import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import MainLayout from '../components/main-layout'
import '../styles/main.scss'
import Footer from '../components/footer'
import ListingsLocation from '../components/listings-location'
import ListingsList from '../components/listings-list'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Pagination from '../components/pagination'
import PageWrapper from '../components/page/page-wrapper'
import SearchProvider from '../contexts/searchContext'
import Header from '../components/header/header'
import { Helmet } from 'react-helmet'
import ServicesBlock from '../components/page/services-block-components'
import RichTextBlockComponent from '../components/page/richtext-block-component'

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema

export const query = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        titleTemplate
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

interface ListingsPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        titleTemplate: string
      }
    }
  }
}

export const ListingsPage: React.FC<ListingsPageProps> = (props) => {
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <SearchProvider>
        <MainLayout className="listings-page">
          <Helmet title={t(`Rooms`)} titleTemplate={props.data.site.siteMetadata.titleTemplate} />
          <Header variant="listings" />

          <div className={`listings container-xxl`}>
            {/*<div className="listings__controls row mt-3 d-none d-lg-flex"><ListingsSortSelector /></div>*/}
            <div className="row d-flex flex-nowrap flex-column flex-xl-row">
              <ListingsList />
              <ListingsLocation />
            </div>

            <Pagination />
          </div>

          <RichTextBlockComponent
            data={{
              __component: `content.rich-text`,
              id: 0,
              text: t(`Unglaublich aber war Service`).toUpperCase() + `\n## SERVICE`,
            }}
          />
          <ServicesBlock />

          <Footer />
        </MainLayout>
      </SearchProvider>
    </PageWrapper>
  )
}

export default ListingsPage
