import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ChevronLeft from '../images/chevron-left.inline.svg'
import ChevronRight from '../images/chevron-right.inline.svg'
import ReactPaginate from 'react-paginate'
import { SearchContext } from '../contexts/searchContext'

const Pagination: React.FC = () => {
  const { t } = useTranslation()
  const { roomTypes, pageSize, currentPage, setCurrentPage, mapVisible } = React.useContext(SearchContext)
  const roomCount = roomTypes.length
  const pageCount = Math.ceil(roomCount / pageSize)

  return (
    <div className={`row ${mapVisible ? `d-none d-lg-block` : ``}`}>
      <div className="pagination--custom">
        <div className="pagination__indicator mb-2">
          {pageCount} {t(`page`, { count: pageCount })}, {roomCount} {t(`room`, { count: roomCount })}
        </div>
        <ReactPaginate
          containerClassName="pagination__container"
          pageClassName="pagination__page"
          activeClassName="pagination__page--active"
          previousClassName="pagination__prev"
          nextClassName="pagination__next"
          disabledClassName="pagination__control--disabled"
          breakClassName="pagination__ellipsis"
          pageCount={pageCount}
          forcePage={currentPage}
          onPageChange={(pageObject) => {
            setCurrentPage(pageObject.selected)
          }}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          previousLabel={<ChevronLeft />}
          nextLabel={<ChevronRight />}
        />
      </div>
    </div>
  )
}
export default Pagination
