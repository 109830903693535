import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'
import { SearchContext } from '../contexts/searchContext'
import PriceSticker from './price-sticker'
import { getRoomUrl } from '../../../../shared/config/room'
import AreaIcon from '../images/icon-area.inline.svg'
import { useLocation } from '@reach/router'
import roomImagePlaceholder from '../images/room-placeholder.jpg'

interface ListingBlockProps {
  id: number
  imageUrl?: string
  location: string
  name: string
  tags?: string[]
  size: string
  rent: number
  utilities?: number
  highlighted: boolean
  setHighlighted: (index: number) => void
}

export const ListingBlock: React.FC<ListingBlockProps> = ({
  id,
  imageUrl,
  location,
  name,
  size,
  rent,
  utilities,
  highlighted,
  setHighlighted,
}) => {
  const { t } = useTranslation()
  const { getUrlSearchParams } = React.useContext(SearchContext)
  const roomDetailUrl = getRoomUrl(id, name) + getUrlSearchParams()
  const windowLocation = useLocation()
  const fullEncodedUrl = encodeURI(windowLocation.protocol + `//` + windowLocation.host + getRoomUrl(id, name))

  return (
    <div
      className={`listing align-self-start col-12  ${highlighted ? ` highlighted` : ``}`}
      onMouseOver={() => setHighlighted(id)}
    >
      <Link className="listing__background" to={roomDetailUrl} />
      <div className="listing__content">
        <div className="d-flex align-items-center">
          <div className="listing__infos">
            <div className="listing__location">
              <span className="align-baseline">{location}</span>
            </div>

            <div className="listing__name">{name}</div>

            <PriceSticker price={rent.toFixed(0)} from />

            <div className="listing__details">
              <div className="listing__details-line">
                <AreaIcon /> {size}
              </div>
              <div className="listing__details-line">
                <i className="icomoon icomoon-coin-euro" /> {(rent - (utilities ?? 0)).toFixed(0)}€/{t(`month`)}
              </div>
              {!!utilities && (
                <div className="listing__details-line listing__details-utilities">
                  <i className="icomoon icomoon-database" /> {utilities.toFixed(0)}€/{t(`month`)}
                </div>
              )}
            </div>
          </div>
          <div className="listing__social" onClick={(event) => event.stopPropagation()}>
            <div className="listing__social-cta">{t(`Share it`)}</div>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${fullEncodedUrl}`} target="_blank" rel="noopener">
              <i className="icomoon icomoon-facebook" />
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${fullEncodedUrl}`} target="_blank" rel="noopener">
              <i className="icomoon icomoon-twitter" />
            </a>
          </div>
        </div>
        <div className="listing__details">
          <div className="listing__details-line">
            <AreaIcon /> {size}
          </div>
          <div className="listing__details-line">
            <i className="icomoon icomoon-coin-euro" /> {(rent - (utilities ?? 0)).toFixed(0)}€/{t(`month`)}
          </div>
          {!!utilities && (
            <div className="listing__details-line listing__details-utilities">
              <i className="icomoon icomoon-database" /> {utilities.toFixed(0)}€/{t(`month`)}
            </div>
          )}
        </div>
        <div className="listing__image">
          {imageUrl !== undefined ? (
            <img src={imageUrl} />
          ) : (
            <>
              <img src={roomImagePlaceholder} />
              <div className="listing__image-no-image">
                <i className="icomoon icomoon-image" />
                <br />
                {t(`No image available`)}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
ListingBlock.defaultProps = {
  highlighted: false,
}
export default ListingBlock
