import * as React from 'react'
import { MapContainer, TileLayer, ZoomControl, Marker } from 'react-leaflet'
import { divIcon, LatLngExpression } from 'leaflet'
import { SearchContext } from '../contexts/searchContext'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { CmsLocations } from '../services/cms'
import mapMarkerIcon from '../images/zymma-z.svg'
import VirtualTourBlockComponent from './page/virtual-tour-block-component'

const defaultPosition: LatLngExpression = [52.518843, 13.402503]
const defaultZoom = 15

enum DetailsType {
  Building = `building`,
  Location = `location`,
  VirtualTour = `virtualTour`,
}

const ListingsLocation: React.FC = () => {
  const { language } = useI18next()
  const { t } = useTranslation()
  const mapRef = React.useRef<L.Map>()
  const { roomTypes, currentPage, locationObject } = React.useContext(SearchContext)
  const [detailsType, setDetailsType] = React.useState<DetailsType>(DetailsType.Building)

  const markerPosition = React.useMemo<LatLngExpression>(() => {
    for (const roomType of roomTypes) {
      if (roomType?.addresses[0]?.coordinates?.lat && roomType?.addresses[0]?.coordinates?.lng) {
        return [roomType.addresses[0].coordinates.lat, roomType.addresses[0].coordinates.lng]
      }
    }

    return defaultPosition
  }, [roomTypes])

  React.useEffect(() => {
    const map = mapRef.current
    if (map === undefined) return

    map.panTo(markerPosition)
  }, [markerPosition])

  React.useEffect(() => {
    // Hide sub list on roomtype change, highlight change, page change
  }, [currentPage, roomTypes])

  return (
    <div className="listings__location col-12 col-lg-auto">
      <div
        className="listings__location-header"
        style={{ backgroundImage: `url('${locationObject?.backgroundImage?.url ?? ``}')` }}
      >
        <div className="listings__location-name">{locationObject?.name}</div>
        <div className="listings__location-district">{locationObject?.district}</div>
      </div>
      <div className="listings__location-content">
        <div className="listings__location-description">
          {CmsLocations.getLocalDescription(locationObject, language)}
        </div>
        <div className="listings__location-details-title">{t(`Property details`)}</div>
        <div className="listings__location-details-type">
          <input
            type="radio"
            name="details-type"
            id="details-building"
            className="listings__location-details-radio"
            hidden
            defaultChecked
            value={DetailsType.Building}
            onChange={(event) => {
              setDetailsType(event.target.value as DetailsType)
            }}
          />
          <label className="collapsible-section__label" htmlFor="details-building">
            <div>{t(`Building`)}</div>
          </label>
          <input
            type="radio"
            name="details-type"
            id="details-location"
            className="listings__location-details-radio"
            hidden
            defaultChecked={false}
            value={DetailsType.Location}
            onChange={(event) => {
              setDetailsType(event.target.value as DetailsType)
            }}
          />
          <label className="collapsible-section__label" htmlFor="details-location">
            <div>{t(`location_amenities`)}</div>
          </label>
          {!!locationObject?.virtualTour && (
            <>
              <input
                type="radio"
                name="details-type"
                id="details-tour"
                className="listings__location-details-radio"
                hidden
                defaultChecked={false}
                value={DetailsType.VirtualTour}
                onChange={(event) => {
                  setDetailsType(event.target.value as DetailsType)
                }}
              />
              <label className="collapsible-section__label" htmlFor="details-tour">
                <div>{t(`3D virtual Tour`)}</div>
              </label>
            </>
          )}
        </div>
        <div className="listings__location-details">
          {detailsType === DetailsType.Building && (
            <>
              {locationObject?.buildingAmenities?.map((amenity, index) => {
                return (
                  <div key={`buildingAmenity` + index} className="listings__location-details-building">
                    <div className="listings__location-details-icon">
                      <img src={amenity.icon.url} />
                    </div>
                    <div className="listings__location-details-label">
                      {CmsLocations.getLocalAmenityName(amenity, language)}
                    </div>
                  </div>
                )
              })}
            </>
          )}
          {detailsType === DetailsType.Location && (
            <>
              {locationObject?.locationAmenities?.map((amenity, index) => {
                return (
                  <div key={`locationAmenity` + index} className="listings__location-details-location">
                    <div className="listings__location-details-icon">
                      <img src={amenity.icon.url} />
                    </div>
                    <div className="listings__location-details-label">
                      {CmsLocations.getLocalAmenityName(amenity, language)}
                    </div>
                  </div>
                )
              })}
            </>
          )}
          {detailsType === DetailsType.VirtualTour && locationObject?.virtualTour !== undefined && (
            <VirtualTourBlockComponent data={locationObject?.virtualTour} />
          )}
        </div>
        <div className="listings__map position-relative justify-self-stretch">
          {typeof window !== `undefined` && (
            <MapContainer
              center={defaultPosition}
              scrollWheelZoom={false}
              zoom={defaultZoom}
              whenCreated={(map) => {
                mapRef.current = map
              }}
              zoomControl={false}
            >
              <TileLayer
                attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
                url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
                maxZoom={18}
                className="listings__map-tile-layer"
              />
              <Marker
                position={markerPosition}
                icon={divIcon({
                  html: `<img src="${mapMarkerIcon}">`,
                  iconSize: [86, 86],
                  className: `listings__map-marker`,
                })}
              />
              <ZoomControl position="topright"></ZoomControl>
            </MapContainer>
          )}
        </div>
      </div>
    </div>
  )
}
ListingsLocation.defaultProps = {
  className: ``,
}
export default ListingsLocation
