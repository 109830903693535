import * as React from 'react'
import ListingBlock from '../components/listing-block'
import { SearchContext } from '../contexts/searchContext'
import { useEffect } from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { CmsRoomTypes } from '../services/cms'

interface ListingsListProps {
  className?: string
}

const ListingsList: React.FC<ListingsListProps> = ({ className }) => {
  const {
    location,
    startDate,
    roomTypes,
    pageSize,
    currentPage,
    highlightedRoom,
    setHighlightedRoom,
    doSearch,
    searchInitialized,
    isSearching,
  } = React.useContext(SearchContext)
  const roomCount = roomTypes.length
  const { t } = useTranslation()
  const { language } = useI18next()

  useEffect(() => {
    if (!searchInitialized && location !== `` && startDate !== ``) {
      // Populate the listings when loading
      doSearch()
    }
  }, [searchInitialized, location, startDate])

  const renderListings = () => {
    const renderedListings = []
    for (
      let listingIndex = pageSize * currentPage;
      listingIndex < pageSize * (currentPage + 1) && listingIndex < roomCount;
      listingIndex++
    ) {
      const roomType = roomTypes[listingIndex]
      renderedListings.push(
        <ListingBlock
          key={`listing_${listingIndex}`}
          id={roomType.id}
          imageUrl={roomType.images[0]?.formats?.small?.url ?? undefined}
          location={roomType.location.name}
          name={CmsRoomTypes.getLocalName(roomType, language)}
          size={CmsRoomTypes.getSizeString(roomType)}
          rent={roomType.priceData.value / 100}
          utilities={roomType.utilities}
          highlighted={highlightedRoom === roomType.id}
          setHighlighted={setHighlightedRoom}
        />,
      )
    }

    return renderedListings
  }

  return (
    <div className={`listings__list col-12 col-lg d-flex flex-column`}>
      {roomCount > 0 && renderListings()}
      {roomCount === 0 && searchInitialized && !isSearching && (
        <div className="listings__list-empty">{t(`listings_no_room`)}</div>
      )}
    </div>
  )
}
ListingsList.defaultProps = {
  className: ``,
}
export default ListingsList
